/* Template Name: Techwind - Multipurpose Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.7.0
   Created: May 2022
   File: Main Css File
*/


// FONTS
@import "custom/fonts";

// general
@import "custom/general";

// components
@import "components/buttons";
@import "components/breadcrumb";

// structure
@import "custom/structure/topnav";
@import "custom/structure/footer";

// pages
@import "custom/pages/helper";
@import "custom/pages/hero";
@import "custom/pages/countdown";
@import "custom/pages/portfolio";
@import "custom/pages/contact";
@import "custom/pages/custom";

// Plugins
@import "custom/plugins/swiper-slider";
@import "custom/plugins/testi";
@import "custom/plugins/choices";
@import "custom/plugins/datepicker";

@tailwind base;

@layer base {
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        --tw-ring-color: theme('colors.green.500');
        border-color: var(--tw-ring-color);
    }

    [type="checkbox"],
    [type="radio"] {
        color: theme('colors.green.500');
    }

    [type="checkbox"]:focus,
    [type="radio"]:focus {
        --tw-ring-color: theme('colors.green.500');
    }

    button:focus,
    a:focus {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
        border-color: #93c5fd;
        outline: none;
    }
}
@tailwind components;
@tailwind utilities;

:root {
    --theme-primary-color: theme('colors.primary.700');
    --theme-secondary-color: theme('colors.secondary.700');
}

@import "user-content";


